import React from "react"
import AccountUk from "../../components/body/pages/en-gb/accounts"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const AccountPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-gb/accounts"}
      title="Open a Kuda Account | The Money App for Africans"
      description="Open a secure Kuda account in minutes, and start using it immediately. Deposit, spend and send money across borders. Join Kuda today!"
    />
    <AccountUk />
  </Layout>
)

export default AccountPage
